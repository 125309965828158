import styled from "styled-components";

interface PillProperties {
  filled: boolean;
}

const Pill = styled.span<PillProperties>`
  padding: 0.15em 0.6em;
  /* border: 1px solid ${(props) => props.theme.colors.text.body}; */
  border: 1px solid #c3cbd1;
  background-color: ${(props) => (props.filled ? "#6c7074" : "clear")};
  color: ${(props) => (props.filled ? "white" : "black")};
  font-weight: bold;
  border-radius: 8px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
`;

export { Pill };
