import React from "react";
import styled from "styled-components";
import { Pill } from "../../components/Pill";

const PillWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const SkillSection = () => {
  return (
    <article>
      <h3>SKILLS</h3>
      <PillWrapper>
        <Pill filled={true}>Leadership</Pill>
        <Pill filled={true}>Mentoring</Pill>
        <Pill filled={true}>Team building</Pill>
        <Pill filled={true}>Frontend development</Pill>
        <Pill filled={true}>iOS development</Pill>
        <Pill filled={true}>React</Pill>
        <Pill filled={true}>Swift</Pill>
        <Pill filled={true}>UIKit</Pill>
        <Pill filled={true}>SwiftUI</Pill>
        <Pill filled={true}>TypeScript</Pill>
        <Pill filled={true}>JavaScript</Pill>
        <Pill filled={true}>Node.js</Pill>
      </PillWrapper>
    </article>
  );
};

export default SkillSection;
